import React, { useState, useRef, useEffect } from "react";
import ReactGA from "react-ga";
import { NavLink } from "react-router-dom";
import SalamiText from "../images/Salami.png";
import gsap, { TweenLite } from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";
import useWindowSize from "../../../../../Hooks/UseWindowSize";
import Circle from "../../../../common/images/Circle.png";
import CircleAnimation from "../../../../common/images/Circle.gif";
import SprawdzGdzieKupic from "../../../../common/images/gdzie-kupic.png";
import SalamiSlice from "./images/salami.png";
import Chili from "./images/chili.png";
import Corn1 from "./images/kukurydza1.png";
import Corn2 from "./images/kukurydza2.png";
import Corn3 from "./images/kukurydza3.png";
import PizzaAnimation from "../images/pizza-salami.png";
import PizzaAnimationMobile from "../images/pizza-salami-mobile.png";
import useProgressiveImage from "../../../../../Hooks/UseProgressiveImage";
import "./Salami.scss";

const Salami = () => {
  const [backgroundCircle, setBackgroundCircle] = useState(Circle);
  const { width } = useWindowSize();
  const loaded = useProgressiveImage(PizzaAnimation);
  const placeholder = PizzaAnimationMobile;
  // eslint-disable-next-line
  const [scrollMagic, setScrollMagic] = useState({
    timelineOne: gsap.timeline(),
  });
  const { timelineOne } = scrollMagic;
  const isDesktop = width >= 1024;

  const pizzaRef = useRef();
  const pizzaTween = useRef();
  const pizzaMobileAnimation = useRef();

  const killPizzaMobileAnimation = () => {
    if (pizzaMobileAnimation.current) {
      pizzaMobileAnimation.current.kill();
      if (ScrollTrigger.getById("pizza-mobile-st")) {
        ScrollTrigger.getById("pizza-mobile-st").kill();
      }
    }
  };

  useEffect(() => {
    if (width < 1024) {
      const pizzaHeight = document
        .getElementById("animation-container")
        .getBoundingClientRect().height;
      killPizzaMobileAnimation();
      pizzaMobileAnimation.current = gsap.to(pizzaRef.current, {
        backgroundPositionY: `-${pizzaHeight * 17}px`,
        ease: "steps(17)",
        scrollTrigger: {
          id: "pizza-mobile-st",
          trigger: ".subpage",
          start: "top top",
          end: "+=100",
          pin: true,
          scrub: true,
          invalidateOnRefresh: true,
        },
      });
    }
    return killPizzaMobileAnimation;
    // eslint-disable-next-line
  }, [width]);

  useEffect(() => {
    if (width >= 1024) {
      killPizzaMobileAnimation();
      const pizzaHeight = document
        .getElementById("animation-container")
        .getBoundingClientRect().height;
      pizzaTween.current = TweenLite.to(pizzaRef.current, {
        duration: 0.5,
        backgroundPositionY: `-${pizzaHeight * 17}px`,
        paused: true,
        ease: "steps(17)",
      });
    }
  }, [width]);

  useEffect(() => {
    // subpage animation
    const mainAnimation = timelineOne.staggerFromTo(
      [".salami", ".circle", ".description", ".pizza"],
      0.5,
      { y: "20%", opacity: 0 },
      { y: "0", opacity: 1 },
      0.1
    );

    mainAnimation.play();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    const findShopButton =
    document.querySelector(".find-btn_salami");

    findShopButton.addEventListener("click", () => {
      const ev = {
        category: 'Znajdz sklep',
        action: 'Znajdz sklep - pizza',
        label: 'Znajdz sklep - pizza_salami'
      }
      // console.log("will log event", ev)
      ReactGA.event(ev);
    });
  }, [])

  const onMouseEnterHandler = () => {
    if (pizzaTween.current) {
      pizzaTween.current.play();
    }
  };
  const onMouseLeaveHandler = () => {
    if (pizzaTween.current) {
      pizzaTween.current.reverse();
    }
  };

  return (
    <div className="subpageMainBackground">
      <div className="salami">
        <img src={SalamiText} alt="Salami" />
      </div>
      <NavLink to="/znajdz-sklep?search=salami" style={{ margin: 0 }} className="find-btn_salami">
        <div
          className="circle"
          onMouseOver={() => setBackgroundCircle(CircleAnimation)}
          onMouseLeave={() => setBackgroundCircle(Circle)}
        >
          <img
            src={backgroundCircle}
            alt="Kolko"
            className="circleAnimationSubpage"
          />
          <div className="button">
            <img
              className="buy"
              src={SprawdzGdzieKupic}
              alt="SprawdzGdzieKupic"
            />
          </div>
        </div>
      </NavLink>
      <div style={{ flex: "100%" }}></div>
      <div className="pizza outer" id="animation-container">
        <div
          className="pizzaAnimation inner"
          alt=""
          id="animation"
          onMouseOver={isDesktop ? onMouseEnterHandler : () => {}}
          onMouseLeave={isDesktop ? onMouseLeaveHandler : () => {}}
          ref={pizzaRef}
          style={
            width > 1023
              ? {
                  backgroundImage: `url(${loaded || placeholder})`,
                }
              : {
                  backgroundImage: `url(${PizzaAnimationMobile})`,
                }
          }
        />
      </div>
      <p className="description">
        Guseppe Salami to pyszna{" "}
        <span className="bold">pizza o wyrazistym smaku ostrego salami</span>,
        wzbogacona papryką pepperoni i&nbsp;soczystą kukurydzą. Wszystko to na
        pysznym sosie pomidorowym, doprawionym wyselekcjonowanymi ziołami.{" "}
        <span className="bold">
          To wyrazisty smak dla odważnych, lubiących gorące i&nbsp;pikantne
          potrawy!
        </span>
      </p>

      <div className="ingredients_desktop">
        <img src={SalamiSlice} alt="Salami" className="desktop_salami" />
        <img src={Chili} alt="Chili" className="desktop_chili" />
        <img src={Corn1} alt="Kukurydza" className="desktop_corn1" />
        <img src={Corn2} alt="Kukurydza" className="desktop_corn2" />
        <img src={Corn3} alt="Kukurydza" className="desktop_corn3" />
      </div>
    </div>
  );
};

export default Salami;
