import React from "react";
import LazyLoad from "react-lazyload";
import { useLocation } from "react-router-dom";
import NavBar from "../../components/NavBar/NavBar";
import Home from "../../components/Home/Home";
import About from "../../components/About/About";
import More from "../../components/More/More";
import GuseppePullsIn from "../../components/GuseppePullsIn/GuseppePullsIn";
import Footer from "../../components/Footer/Footer";
import Spot from "../../components/Spot/Spot";
import GameSection from "../../components/Game/GameSection";
import "./main.scss";

const Main = () => {
  const [isMobileProductListOpen, setIsMobileProductListOpen] = React.useState(
    false
  );
  const { hash } = useLocation();

  const handleCloseNavbar = () => {
    setIsMobileProductListOpen(false);
  };

  const handleMobileProductListClick = (e) => {
    e.preventDefault();
    setIsMobileProductListOpen(true);
  };

  React.useLayoutEffect(() => {
    window.addEventListener("load", () => {
      if (hash) {
        setTimeout(() => {
          const id = hash.replace("#", "");
          const element = document.getElementById(id);
          if (element) {
            element.scrollIntoView();
          }
        }, 0);
      }
    });
    // eslint-disable-next-line
  }, []);

  return (
    <div>
      <NavBar
        isMobileProductListOpen={isMobileProductListOpen}
        onClose={handleCloseNavbar}
      />
      <Home handleMobileProductListClick={handleMobileProductListClick} />
      <About />
      <More />
      <LazyLoad height="200vh">
        <GuseppePullsIn />
      </LazyLoad>
      <LazyLoad height="200vh">
        <Spot />
      </LazyLoad>
      <LazyLoad height="200vh" offset={200}>
        <GameSection />
      </LazyLoad>

      <Footer />
    </div>
  );
};

export default Main;
