import React, { useState, useEffect } from "react";
import * as ScrollMagic from "scrollmagic";
import gsap from "gsap";
import Salami from "./Salami/Salami";
import SalamiBOX from "./images/pizza-salami-pudelko.png";
import IngredientsDesktop from "./Ingredients/IngredientsDesktop/IngredientsDesktop";
import "./Main.scss";
import Ingredients from "./Ingredients/Ingredients";
import InstructionDesktop from "../Instruction/InstructionDesktop/InstructionDesktop";
import Informations from "../Informations/Informations";
import useWindowSize from "../../../../Hooks/UseWindowSize";

const Main = () => {
  const size = useWindowSize();
  const { width } = size;
  // eslint-disable-next-line
  const [scrollMagic, setScrollMagic] = useState({
    controller: new ScrollMagic.Controller(),
    timelineOne: gsap.timeline(),
  });
  const { controller, timelineOne } = scrollMagic;
  useEffect(() => {
    // pizzaBox animation
    timelineOne.fromTo(
      ".salamibox",
      { y: "100px", opacity: 0 },
      { y: "0", opacity: 1 }
    );

    new ScrollMagic.Scene({
      triggerElement: ".skos2",
      triggerHook: 0.95,
    })
      .setTween(timelineOne)
      .addTo(controller);
    // eslint-disable-next-line
  }, []);
  return (
    <>
      <main className="subpageMain">
        <Salami />
        <InstructionDesktop />
        <div className="skos2">
          <div className="salamibox">
            <img
              src={SalamiBOX}
              alt="Salami - pizza o wyrazistym smaku ostrego salami na grubym ciescie"
            />
            <div className="palmOilFree">
              <img
                alt="palm oil free"
                src={`${process.env.PUBLIC_URL}/images/palm-oil-free.png`}
              /> 
            </div>
          </div>
          <Ingredients />
          <IngredientsDesktop />
          {width > 1023 ? <Informations /> : null}
        </div>
      </main>
    </>
  );
};

export default Main;
