import React, { useState, useEffect } from "react";
import * as ScrollMagic from "scrollmagic";
import gsap from "gsap";
import SalamiIngredientsMainMobile from "./images/salami-papryka-kukurydza.png";

const Ingredients = () => {
  // eslint-disable-next-line
  const [scrollMagic, setScrollMagic] = useState({
    controller: new ScrollMagic.Controller(),
    timelineOne: gsap.timeline(),
  });
  const { controller, timelineOne } = scrollMagic;
  useEffect(() => {
    // instruction animation
    timelineOne.staggerFromTo(
      [".salamiIngredientsMainMobile"],
      0.5,
      { y: "50px", opacity: 0 },
      { y: "0", opacity: 1 },
      0.1
    );

    new ScrollMagic.Scene({
      triggerElement: ".ingredients",
      triggerHook: 0.7,
    })
      .setTween(timelineOne)
      .addTo(controller);
    // eslint-disable-next-line
  }, []);
  return (
    <div className="ingredients">
      <img
        src={SalamiIngredientsMainMobile}
        alt="Salami papryka kukurydza"
        className="salamiIngredientsMainMobile"
      />
    </div>
  );
};

export default Ingredients;
