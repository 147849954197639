import React from "react";
import "./PizzaMenu.scss";
import { pizzas } from "../../constants";

const PizzaMenu = ({ pizza }) => {
  const sliderNavigationHandler = () => {
    window.scroll({
      top: 0,
      left: 0,
    });
  };

  return (
    <a href={pizzas[pizza].path} style={{ textDecoration: "none" }}>
      <div
        className="footerMenuItem"
        onClick={() => sliderNavigationHandler(pizza)}
      >
        {pizzas[pizza].name}
      </div>
    </a>
  );
};

export default PizzaMenu;
