import React, { Suspense } from "react";
import "./App.scss";
import * as ScrollMagic from "scrollmagic";
import { TimelineMax, TweenMax } from "gsap";
import { ScrollMagicPluginGsap } from "scrollmagic-plugin-gsap";
import Main from "./pages/Main/Main";
import SalamiSubpage from "./pages/SalamiSubpage/SalamiSubpage";
import { Helmet } from "react-helmet";
// import Cookies from "./components/Cookies/Cookies";
import NewCookies from "./components/Cookies/newCookies";
import { Route, Switch } from "react-router-dom";
import Loader from "./components/Loader/Loader";


const HamMushroomSubpage = React.lazy(() => import("./pages/HamMushroom/HamMushroomSubpage"));
const CheeseSubpage = React.lazy(() => import("./pages/Cheese/CheeseSubpage"));
// const CheeseHotSubpage = React.lazy(() => import("./pages/CheeseHot/CheeseHotSubpage"));
const HamChorizoSubpage = React.lazy(() => import("./pages/HamChorizo/HamChorizoSubpage"));
const ChickenSubpage = React.lazy(() => import("./pages/Chicken/ChickenSubpage"));
const HamSubpage = React.lazy(() => import("./pages/Ham/HamSubpage"));
const KebabSubpage = React.lazy(() => import("./pages/Kebab/KebabSubpage"));
const HamGarlicSubpage = React.lazy(() => import("./pages/HamGarlic/HamGarlicSubpage"));
const HawaiiSubpage = React.lazy(() => import("./pages/Hawaii/HawaiiSubpage"));
const CheddarSubpage = React.lazy(() => import("./pages/SalamiCheddarSauce/CheddarSubpage"))
// const ChickenGrilledVegetablesSubpage = React.lazy(() => import("./pages/ChickenGrilledVegetables/ChickenGrilledVegetablesSubpage"));
const FindShop = React.lazy(() => import("./pages/FindShop/FindShop"));
const HowToBake = React.lazy(() => import("./pages/HowToBake/HowToBake"));
const LearnMore = React.lazy(() => import("./pages/LearnMore/LearnMore"));
const G2A = React.lazy(() => import("./pages/G2A/G2A"));
const G2ATerms = React.lazy(() => import("./pages/G2A/G2ATerms"));
const MobileGame = React.lazy(() => import("./pages/Game/Game"));
const UnknownPage = React.lazy(() => import("./pages/Unknown/Unknown"));

ScrollMagicPluginGsap(ScrollMagic, TweenMax, TimelineMax);

function App() {
  return (
    <div className="App">
      <Switch>
        <Route
          path="/"
          exact
          render={() => (
            <Suspense
              fallback={
                <div>
                  <Loader />
                </div>
              }
            >
              <Helmet>
                <title>Guseppe, pizza mrożona, która wciąga! | Dr. Oetker</title>
                <meta
                  name="description"
                  content="Guseppe to pizza mrożona na pysznym cieście 🍕, 
                  puszystym w środku i chrupiącym na zewnątrz, 
                  ze składnikami ułożonymi aż po brzegi. Guseppe wciąga!"
                />
              </Helmet>
              <Main />
            </Suspense>
          )}
        />
        <Route
          path="/pizza-salami"
          exact
          render={() => (
            <Suspense
              fallback={
                <div>
                  <Loader />
                </div>
              }
            >
              <Helmet>
                <title>Pizza mrożona Guseppe Salami</title>
                <meta
                  name="description"
                  content="Pizza mrożona Guseppe Salami 🍕 o wyrazistym smaku ostrego salami,
                  wzbogacona papryką pepperoni i soczystą kukurydzą. Wyrazisty smak dla
                  odważnych!"
                />
              </Helmet>
              <SalamiSubpage />
            </Suspense>
          )}
        />
        <Route
          path="/pizza-szynka-pieczarka"
          exact
          render={() => (
            <Suspense
              fallback={
                <div>
                  <Loader />
                </div>
              }
            >
              <Helmet>
                <title>
                  Pizza mrożona Guseppe Szynka &amp; Pieczarka (Ham &amp; Mushroom)
                </title>
                <meta
                  name="description"
                  content="Pizza mrożona Guseppe Szynka &amp; Pieczarka 🍕. Pyszna kompozycja zawsze lubianej szynki i aromatycznych pieczarek, czerwonej cebulki, ziół i przypraw. Koniecznie sprawdź!"
                />
              </Helmet>
              <HamMushroomSubpage />
            </Suspense>
          )}
        ></Route>
        <Route
          path="/pizza-4-sery"
          exact
          render={() => (
            <Suspense
              fallback={
                <div>
                  <Loader />
                </div>
              }
            >
              <Helmet>
                <title>Pizza mrożona Guseppe 4 sery (4 Cheese)</title>
                <meta
                  name="description"
                  content="Pizza mrożona Guseppe 4 sery 🍕 to prawdziwa serowa uczta. Niepowtarzalna kompozycja serów: Edamskiego, Mozzarella, Tylżyckiego i sera pleśniowego."
                />
              </Helmet>
              <CheeseSubpage />
            </Suspense>
          )}
        />
        {/* <Route
          path="/pizza-4-sery-hot"
          exact
          render={() => (
            <Suspense
              fallback={
                <div>
                  <Loader />
                </div>
              }
            >
              <Helmet>
                <title>Pizza mrożona Guseppe 4 cheese HOT</title>
                <meta
                  name="description"
                  content="Pizza mrożona Guseppe 4 sery 🍕 to pikantna pizza z pyszną kompozycją serów: Edamskiego, Mozzarella, Tylżyckiego i sera pleśniowego"
                />
              </Helmet>
              <CheeseHotSubpage />
            </Suspense>
          )}
        /> */}
        <Route
          path="/pizza-szynka-chorizo"
          exact
          render={() => (
            <Suspense
              fallback={
                <div>
                  <Loader />
                </div>
              }
            >
              <Helmet>
                <title>Pizza mrożona Guseppe Ham & Chorizo smak Farmerska</title>
                <meta
                  name="description"
                  content="Pizza mrożona Guseppe z kompozycją dwóch wędlin: aromatycznej szynki i wyraźnego chorizo oraz dodatkiem słodkiej kukurydzy i ostrej cebuli. Smakuje jak robiona w domu! "
                />
              </Helmet>
              <HamChorizoSubpage />
            </Suspense>
          )}
        />
        <Route
          path="/pizza-kurczak-curry"
          exact
          render={() => (
            <Suspense
              fallback={
                <div>
                  <Loader />
                </div>
              }
            >
              <Helmet>
                <title>Pizza mrożona Guseppe Chicken Curry</title>
                <meta
                  name="description"
                  content="Pizza mrożona Guseppe z kurczakiem 🍕 w orientalnej przyprawie
curry, wzbogacona imbirem, mleczkiem kokosowym, papryką, czosnkiem, chilli
i kardamonem."
                />
              </Helmet>
              <ChickenSubpage />
            </Suspense>
          )}
        />
        <Route
          path="/pizza-szynka"
          exact
          render={() => (
            <Suspense
              fallback={
                <div>
                  <Loader />
                </div>
              }
            >
              <Helmet>
                <title>Pizza mrożona Guseppe z szynką (Ham)</title>
                <meta
                  name="description"
                  content="Pizza mrożona Guseppe z szynką 🍕, której smak został wzbogacony świeżą
cebulą, soczystą czerwoną papryką i aromatycznymi ziołami. Klasyk wśród
pizz, który smakuje każdemu!"
                />
              </Helmet>
              <HamSubpage />
            </Suspense>
          )}
        />
        <Route
          path="/pizza-kebab"
          exact
          render={() => (
            <Suspense
              fallback={
                <div>
                  <Loader />
                </div>
              }
            >
              <Helmet>
                <title>Pizza mrożona Guseppe Kebab</title>
                <meta
                  name="description"
                  content="Pizza mrożona Guseppe Kebab 🍕 to orientalne połączenie smakowe w stylu
tureckim. Idealna kompozycja mięsa wołowego, papryki, grillowanego
bakłażana i czerwonej cebuli."
                />
              </Helmet>
              <KebabSubpage />
            </Suspense>
          )}
        />
        {/* <Route
          path="/pizza-kurczak-grillowane-warzywa"
          exact
          render={() => (
            <Suspense
              fallback={
                <div>
                  <Loader />
                </div>
              }
            >
              <Helmet>
                <title>Pizza mrożona Guseppe Chicken & Grilled Vegetables</title>
                <meta
                  name="description"
                  content="Pizza mrożona Guseppe z kurczakiem i grillowanymi warzywami 🍕 Pizza, która spełni oczekiwania wszystkich amatorów kurczaka, grillowania i oczywiście pizzy!"
                />
              </Helmet>
              <ChickenGrilledVegetablesSubpage />
            </Suspense>
          )}
        /> */}
        <Route 
          path="/pizza-szynka-sos-czosnkowy"
          exact
          render={() => (
            <Suspense
              fallback={
                <div>
                  <Loader />
                </div>
              }
            >
              <Helmet>
                <title>
                  Pizza mrożona Guseppe z szynką i sosem czosnkowym (Ham &amp; Garlic
                  Sauce)
                </title>
                <meta
                  name="description"
                  content="Pizza mrożona Guseppe z szynką i sosem czosnkowym 🍕 to unikalna
kompozycja pizzy z lubianą szynką, sosem czosnkowym, pomidorkami
koktajlowymi oraz czerwoną cebulką."
                />
              </Helmet>
              <HamGarlicSubpage />
            </Suspense>
          )}
        />
        <Route 
          path="/pizza-hawaii"
          exact
          render={() => (
            <Suspense
              fallback={
                <div>
                  <Loader />
                </div>
              }
            >
              <Helmet>
                <title>
                  Pizza mrożona Guseppe Hawaii
                </title>
                <meta
                  name="description"
                  content="Guseppe Hawaii to pizza mrożona 🍕 , której smak tworzy kompozycja delikatnej szynki, słodkiego ananasa, ciągnącego się sera i wyrazistego pomidorowego sosu."
                />
              </Helmet>
              <HawaiiSubpage />
            </Suspense>
          )}
        />
        <Route 
          path="/pizza-salami-cheddar"
          exact
          render={() => (
            <Suspense
              fallback={
                <div>
                  <Loader />
                </div>
              }
            >
              <Helmet>
                <title>
                  Pizza mrożona Guseppe Salami & Cheddar Sauce
                </title>
                <meta
                  name="description"
                  content="Guseppe Salami & Cheddar Sauce to wyjątkowe połączenie lekko pikantnego salami i podwójnej porcji sera cheddar - na obłożeniu i w sosie. 
                  Całość wzbogacona zieloną papryką i doprawiona aromatycznymi ziołami. Wyraziste salami oraz intensywnie chedarowy sos sprawiają, że ta pizza to przepysznie doznanie w każdym kęsie. "
                />
              </Helmet>
              <CheddarSubpage />
            </Suspense>
          )}
        />
        <Route
          path="/znajdz-sklep"
          exact
          render={() => (
            <Suspense
              fallback={
                <div>
                  <Loader />
                </div>
              }
            >
              <Helmet>
                <title>Sprawdź gdzie kupisz mrożoną pizzę Guseppe! | Dr. Oetker</title>
                <meta
                  name="description"
                  content="Skorzystaj z wyszukiwarki sklepów, sprawdź gdzie kupisz ulubioną mrożoną
pizzę Guseppe w swojej okolicy!"
                />
              </Helmet>
              <FindShop />
            </Suspense>
          )}
        />
        <Route
          path="/dowiedz-sie-wiecej"
          exact
          render={() => (
            <Suspense
              fallback={
                <div>
                  <Loader />
                </div>
              }
            >
              <Helmet>
                <title>Dowiedz się więcej o pizzy Guseppe! | Dr. Oetker</title>
                <meta
                  name="description"
                  content="Sprawdź w jaki sposób tworzona jest pyszna pizza Guseppe! 🍕"
                />
              </Helmet>
              <LearnMore />
            </Suspense>
          )}
        />
        <Route
          path="/promocja-g2a"
          exact
          render={() => (
            <Suspense
              fallback={
                <div>
                  <Loader />
                </div>
              }
            >
              <Helmet>
                <title>G2A | Dr. Oetker</title>
                <meta
                  name="description"
                  content="Sprawdź kody zniżkowe G2A na pudełkach Guseppe! 🍕"
                />
              </Helmet>
              <G2A />
            </Suspense>
          )}
        />
                <Route
          path="/g2a"
          exact
          render={() => (
            <Suspense
              fallback={
                <div>
                  <Loader />
                </div>
              }
            >
              <Helmet>
                <title>G2A | Dr. Oetker</title>
                <meta
                  name="description"
                  content=""
                />
              </Helmet>
              <G2ATerms />
            </Suspense>
          )}
        />
        <Route
          path="/jak-upiec"
          exact
          render={() => (
            <Suspense
              fallback={
                <div>
                  <Loader />
                </div>
              }
            >
              <Helmet>
                <title>Jak upiec mrożoną pizzę Guseppe? | Dr. Oetker</title>
                <meta
                  name="description"
                  content="Sprawdź jak upiec mrożoną pizzę Guseppe, zajrzyj do naszej instrukcji pieczenia! 🍕"
                />
              </Helmet>
              <HowToBake />
            </Suspense>
          )}
        />
        <Route
          path="/gra"
          exact
          render={() => (
            <Suspense
              fallback={
                <div>
                  <Loader />
                </div>
              }
            >
              <Helmet>
                <title>Pizza mrożona Guseppe – gra łap składniki | Dr. Oetker</title>
                <meta
                  name="description"
                  content="Zagraj w łapanie składników do pizzy, sprawdź swój refleks. Poznaj naszą grę Guseppe!"
                />
              </Helmet>
              <MobileGame />
            </Suspense>
          )}
        />
        <Route path="*"
        render={() => (
          <Suspense
            fallback={
              <div>
                <Loader />
              </div>
            }
          >
            <Helmet>
              <title>Guseppe, pizza mrożona, która wciąga! | Dr. Oetker</title>
              <meta
                name="description"
                content=""
              />
            </Helmet>
            <UnknownPage />
          </Suspense>
        )}
        />
      </Switch>
      <NewCookies />
    </div>
  );
}

export default App;
