import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { pizzas } from "../../constants";
import "./BurgerMenuModal.scss";

const ProductsList = ({ setPopoverOpen }) => {
  const history = useHistory();
  const [imagesReady, setImagesReady] = useState(false);

  function allLoaded() {
    setImagesReady(true);
  }

  let number = 0;

  useEffect(() => {
    pizzas.forEach((pizza) => {
      const img = new Image();
      img.src = pizza.modalPizza;
      img.onload = () => {
        number++;
        if (number === pizzas.length) {
          allLoaded();
        }
      };
    });
  }, [number]);
  const sliderNavigationHandler = (path) => {
    setPopoverOpen(false);
    history.push({
      pathname: path,
    });
    window.scroll({
      top: 0,
      left: 0,
    });
  };
  return (
    <div className="burgerModal">
      <div
        style={{
          display: "flex",
          flexWrap: "wrap",
          justifyContent: "space-around",
          marginBottom: "100px",
          marginTop: "10px",
          height: "calc(100% - 110px)",
          margin: "auto",
        }}
      >
        {pizzas.map((pizza) => {
          return (
            <div
              className="burgerMenuPizzaContainer"
              key={pizza.id}
              onClick={() => sliderNavigationHandler(pizza.path)}
            >
              {imagesReady ? (
                <div
                  style={{
                    backgroundImage: `url(${pizza.modalPizza})`,
                    backgroundPosition: "center",
                    backgroundSize: "contain",
                    backgroundRepeat: "no-repeat",
                    height: "68%",
                    maxHeight: "68%",
                    width: "80%",
                    margin: "auto",
                  }}
                ></div>
              ) : null}
              {pizza.name}
            </div>
          );
        })}
        {/* FOR LAYOUT PURPOSE */}
        <div className="burgerMenuPizzaContainer"></div>
        <div className="burgerMenuPizzaContainer"></div>
      </div>
    </div>
  );
};

export default ProductsList;
