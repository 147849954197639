const CloseIcon = () => {
  return (
    <svg
      style={{
        width: "24px",
        height: "24px",
      }}
      viewBox="0 0 24 24"
    >
      <path
        fill="#fffad4"
        d="M20 6.91L17.09 4L12 9.09L6.91 4L4 6.91L9.09 12L4 17.09L6.91 20L12 14.91L17.09 20L20 17.09L14.91 12L20 6.91Z"
      />
    </svg>
  );
};

export default CloseIcon;
