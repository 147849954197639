import React, { useState, useEffect } from "react";
import InstagramGalleryDesktop from "./InstagramFallbackDesktop";
import InstagramFallbackkMobile from "./InstagramFallbackMobile";
import useWindowSize from "../../Hooks/UseWindowSize";
import * as ScrollMagic from "scrollmagic";
import Vegetables from "../../images/GuseppePullsIn/ser-bazylia-pomidor.webp";
import VegetablesFallback from "../../images/GuseppePullsIn/png/ser-bazylia-pomidor.png";
import Fb from "../../images/GuseppePullsIn/mobile/fb-min.png";
import Insta from "../../images/GuseppePullsIn/mobile/insta-min.png";
import gsap from "gsap";
import Underline from "../../images/GuseppePullsIn/mobile/podkreslenie-min.png";
import "./GuseppePullsIn.scss";

const GuseppePullsIn = () => {
  const size = useWindowSize();
  const { width } = size;
  const [child, setChild] = useState({});
  // eslint-disable-next-line
  const [scrollMagic, setScrollMagic] = useState({
    controller: new ScrollMagic.Controller(),
    timelineOne: gsap.timeline(),
  });
  const { controller, timelineOne } = scrollMagic;

  useEffect(() => {
    const interval = setInterval(() => {
      setChild(document.querySelector(`.Loading`));
    }, 100);
    if (child === null) {
      clearInterval(interval);
    }
    return () => clearInterval(interval);
  }, [child]);

  useEffect(() => {
    // findShopContainer animation

    if (child === null) {
      timelineOne.staggerFromTo(
        [
          ".guseppePullsInTitleAnimation",
          ".instaPhotoContainer a:nth-child(1)",
          ".instaPhotoContainer a:nth-child(2)",
          ".instaPhotoContainer a:nth-child(3)",
          ".instaPhotoContainer a:nth-child(4)",
          ".guseppePullsInInstagramIcon",
          ".guseppePullsInFacebookIcon",
        ],
        0.5,
        {
          y: "100px",
          opacity: 0,
        },
        { y: "0", opacity: 1 },
        0.1
      );
      new ScrollMagic.Scene({
        triggerElement: ".guseppePullsInContainer",
        triggerHook: width > 1023 ? 0.7 : 0.9,
        duration: 0,
      })
        .setTween(timelineOne)
        .addTo(controller);
    }

    // eslint-disable-next-line
  }, [child]);
  return (
    <div className="guseppePullsInContainer">
      <div className="guseppePullsInTitleAnimation">
        <h2 className="guseppePullsInTitle">#guseppewciąga</h2>

        <img src={Underline} alt="Linia" className="guseppePullsInUnderline" />
      </div>
      <div className="galleryContainer">
        {width > 1023 ? (
          <InstagramGalleryDesktop />
        ) : (
          <InstagramFallbackkMobile />
        )}
      </div>
      <div style={{ display: "flex", justifyContent: "space-around" }}>
        <div
          style={{ width: "48%", display: "flex", justifyContent: "flex-end" }}
        >
          <a
            href="https://www.instagram.com/pizzaguseppe/?hl=pl"
            target="_blank"
            rel="noopener noreferrer"
            className="guseppePullsInInstagramIcon"
          >
            <img src={Insta} alt="Instagram" style={{ width: "100%" }} />
          </a>
        </div>
        <div
          style={{
            width: "45%",
            display: "flex",
            justifyContent: "flex-start",
          }}
        >
          <a
            href="https://www.facebook.com/PizzaGuseppe/"
            target="_blank"
            rel="noopener noreferrer"
            className="guseppePullsInFacebookIcon"
          >
            <img src={Fb} alt="Facebook" style={{ width: "100%" }} />
          </a>
        </div>
      </div>
      <picture>
        <source srcSet={Vegetables} type="image/webp" />
        <source srcSet={VegetablesFallback} type="image/png" />
        <img alt="Ser bazylia pomidor" className="guseppePullsInVegetables" />
      </picture>
    </div>
  );
};

export default GuseppePullsIn;
