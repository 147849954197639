import React from "react";
import { CookiesProvider,  } from "react-cookie";
import ReactDOM from "react-dom";
import { BrowserRouter as Router } from "react-router-dom";
import gsap from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";
import "./index.scss";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import RouteChangeTracker from "./Hooks/RouteChangeTracker";
import Protect from 'react-app-protect'

// const express = require('express');
// const path = require('path');
// const app = express();

// app.use(express.static(path.join(__dirname, 'build')));

// app.get('/*', function (req, res) {
//   res.sendFile(path.join(__dirname, 'build', 'index.html'));
// });

// app.listen(8000);

gsap.registerPlugin(ScrollTrigger);

ReactDOM.render(
  <React.StrictMode>
    <Router>
      <CookiesProvider>
        <RouteChangeTracker>
          {/* <Protect blur={true} sha512='8ed1aa6f860c0ac710c4fe570e84f38f1b315c093de889514db7c0af1bf6c7efb0bab350b52ee611e3170dab97df935856405fa17a3ff13573023ac4eba4ce2a'> */}
          <App />
          {/* </Protect> */}
        </RouteChangeTracker>
      </CookiesProvider>
    </Router>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
