import React, { useState, useEffect } from "react";
import * as ScrollMagic from "scrollmagic";
import gsap from "gsap";
import ReactPlayer from "react-player";

import "./Spot.scss";

const Spot = () => {
  // eslint-disable-next-line
  const [scrollMagic, setScrollMagic] = useState({
    controller: new ScrollMagic.Controller(),
    timelineOne: gsap.timeline(),
  });
  const { controller, timelineOne } = scrollMagic;

  useEffect(() => {
    timelineOne.staggerFromTo(
      [".spotTitleAnimation", ".videoContainer"],
      0.5,
      { y: "120px", opacity: 0 },
      { y: "0", opacity: 1 },
      0.25
    );

    new ScrollMagic.Scene({
      triggerElement: "#spotTrigger",
      triggerHook: 0.9,
      duration: 0,
    })
      .setTween(timelineOne)
      .addTo(controller);
    // eslint-disable-next-line
  }, []);
  return (
    <div id="spotTrigger">
      <div className="spotTitleAnimation">
        <h2 className="spotTitle">Zobacz nasz spot</h2>

        <img
          src={`${process.env.PUBLIC_URL}/images/Spot/mobile/podkreslenie-min.png`}
          alt="Linia"
          className="spotTitleUnderline"
        />
      </div>
      <div className="videoContainer">
        <ReactPlayer
          url="https://www.youtube.com/watch?v=lT-VKTJS47g"
          className="video"
          width="100%"
          height="100%"
        />
      </div>
    </div>
  );
};

export default Spot;
