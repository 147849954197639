import React, { useState, useEffect } from "react";
import * as ScrollMagic from "scrollmagic";
import gsap from "gsap";
import "./AboutDesktop.scss";

const AboutDesktop = () => {
  // eslint-disable-next-line
  const [scrollMagic, setScrollMagic] = useState({
    controller: new ScrollMagic.Controller(),
    timelineOne: gsap.timeline(),
  });

  const { controller, timelineOne } = scrollMagic;

  useEffect(() => {
    timelineOne.staggerFromTo(
      [
        "#aboutTitleAnimationDesktop",
        "#aboutDescriptionAnimationDesktop",
        ".groupVegetablesDesktop",
        ".basilDesktop",
        ".tomatoDesktop",
        ".mushroomDesktop",
        ".mushroom2Desktop",
      ],
      0.5,
      { y: "120px", opacity: 0 },
      { y: "0", opacity: 1 },
      0.25
    );
    new ScrollMagic.Scene({
      triggerElement: "#titleAnimation",
      triggerHook: 0.8,
    })
      .setTween(timelineOne)
      .addTo(controller);
    // eslint-disable-next-line
  }, []);
  return (
    <>
      <div id="titleAnimation" style={{ overflowY: "hidden" }}>
        <div id="aboutTitleAnimationDesktop">
          <h2 className="aboutTitleDesktop">
            Guseppe - pizza,
            <br /> która wciąga!
          </h2>

          <img
            src={`${process.env.PUBLIC_URL}/images/About/podkreslenie.png`}
            alt="Linia"
            className="underlineDesktop"
          />
        </div>
      </div>
      <div className="aboutContentContainer">
        <div style={{ flexBasis: "40%" }}>
          <div
            className="aboutDescriptionDesktop"
            style={{
              position: "relative",
              overflowY: "hidden",
            }}
          >
            <div id="aboutDescriptionAnimationDesktop">
              <p>
                Pyszne ciasto, delikatnie puszyste w środku i&nbsp;jednocześnie
                przyjemnie chrupiące na zewnątrz, to właśnie tajemnica
                niepowtarzalnego smaku pizzy Guseppe. Dodatki specjalnie dobrane
                i&nbsp;lubiane - ułożone aż po brzegi. Całości smaku dopełniają
                aromatyczne przyprawy i&nbsp;sosy.
              </p>
              <p className="summary">
                Guseppe to pyszna pizza mrożona, zabawa smakiem dla każdego!
              </p>
            </div>
          </div>
        </div>
        <div className="imagesContainerDesktop">
          <img
            alt="Pizza kawalek"
            className="pizzaSliceDesktop"
            src={`${process.env.PUBLIC_URL}/images/About/kawalek-pizzy.png`}
          />
        </div>
      </div>

      <img
        src={`${process.env.PUBLIC_URL}/images/About/bazylia.png`}
        alt="Bazylia"
        className="basilDesktop"
      />

      <img
        src={`${process.env.PUBLIC_URL}/images/About/pomidor.png`}
        alt="Pomidor"
        className="tomatoDesktop"
      />
      <picture>
        <source
          srcSet={`${process.env.PUBLIC_URL}/images/About/pieczarka.webp`}
          type="image/webp"
        />
        <source
          srcSet={`${process.env.PUBLIC_URL}/images/About/png/pieczarka.png`}
          type="image/png"
        />
        <img alt="Pieczarka" className="mushroomDesktop" />
      </picture>

      <picture>
        <source
          srcSet={`${process.env.PUBLIC_URL}/images/About/pieczarka-polowa.webp`}
          type="image/webp"
        />
        <source
          srcSet={`${process.env.PUBLIC_URL}/images/About/png/pieczarka-polowa.png`}
          type="image/png"
        />
        <img alt="Pieczarka" className="mushroom2Desktop" />
      </picture>

      <img
        src={`${process.env.PUBLIC_URL}/images/About/pomidory-bazylia-pieprz.png`}
        alt="Pomidory bazylia pieprz"
        className="groupVegetablesDesktop"
      />
    </>
  );
};

export default AboutDesktop;
