import React from "react";
import Slider from "../Slider/Slider";
import "./home.scss";

const Home = ({ handleMobileProductListClick }) => {
  return (
    <div className="home">
      <div className="backgroundImage">
        <Slider handleMobileProductListClick={handleMobileProductListClick} />
      </div>
    </div>
  );
};

export default Home;
