import React from "react";
import image1 from "../../images/GuseppePullsIn/instagram_basen.jpg";
import image2 from "../../images/GuseppePullsIn/instagram_gracz.jpg";
import image3 from "../../images/GuseppePullsIn/instagram_szkielet.jpg";
import image4 from "../../images/GuseppePullsIn/instagram_impreza.jpg";

const InstagramFallbackMobile = () => {
  return (
    <div className="instaPhotoContainer">
      <a
        href="https://www.instagram.com/pizzaguseppe/"
        rel="noopener noreferrer"
        target="_blank"
        style={{ opacity: "1", transform: "translate(0px, 0px)" }}
      >
        <img src={image1} alt="instagram_basen" />
      </a>
      <a
        href="https://www.instagram.com/pizzaguseppe/"
        rel="noopener noreferrer"
        target="_blank"
        style={{ opacity: "1", transform: "translate(0px, 0px)" }}
      >
        <img src={image2} alt="instagram_gracz" />
      </a>
      <a
        href="https://www.instagram.com/pizzaguseppe/"
        rel="noopener noreferrer"
        target="_blank"
        style={{ opacity: "1", transform: "translate(0px, 0px)" }}
      >
        <img src={image3} alt="instagram_szkielet" />
      </a>
      <a
        href="https://www.instagram.com/pizzaguseppe/"
        rel="noopener noreferrer"
        target="_blank"
        style={{ opacity: "1", transform: "translate(0px, 0px)" }}
      >
        <img src={image4} alt="instagram_impreza" />
      </a>
    </div>
  );
};

export default InstagramFallbackMobile;
