/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import gsap from "gsap";
import { pizzas } from "../../constants";
import useWindowSize from "../../Hooks/UseWindowSize";
import Slider from "react-slick";
import PizzaMenu from "../PizzaMenu/PizzaMenu";
import "./Slider.scss";
let tl = gsap.timeline({ paused: true });

function SamplePrevArrow(props) {
  const [prevButtonImg, setPrevButtonImg] = useState(
    `${process.env.PUBLIC_URL}/images/slider/zakreslenie-biale.png`
  );
  const [animation, setAnimation] = useState({});
  const { onClick, tl } = props;

  useEffect(() => {
    setAnimation(
      gsap.fromTo(
        ".prevButton",
        { scale: 0.99 },
        {
          scale: 0.8,
          paused: true,
          duration: 0.2,
          ease: "linear",
        }
      )
    );
  }, []);

  const handleClick = () => {
    if (tl.totalProgress() === 0) {
      animation.play().then(() => {
        animation.reverse();
      });

      onClick();
    }
  };

  return (
    <button
      className="prevButton"
      onClick={handleClick}
      onMouseEnter={() =>
        setPrevButtonImg(
          `${process.env.PUBLIC_URL}/images/gify/linia-owal-biala-anim.gif`
        )
      }
      onMouseLeave={() =>
        setPrevButtonImg(
          `${process.env.PUBLIC_URL}/images/slider/zakreslenie-biale.png`
        )
      }
    >
      <img
        src={`${process.env.PUBLIC_URL}/images/slider/strzalka-lewo.png`}
        alt="Strzalka lewo"
      />

      <p
        style={{
          fontSize: "16px",
          fontWeight: "700",
          color: "#fffad4",
          marginTop: "30px",
        }}
      >
        poprzednia
      </p>

      <img src={prevButtonImg} alt="Slider linia lewo" className="ovalLineAnimation" />
    </button>
  );
}

function SampleNextArrow(props) {
  const [nextButtonImg, setNextButtonImg] = useState(
    `${process.env.PUBLIC_URL}/images/slider/zakreslenie-czerwone.png`
  );
  const [animation, setAnimation] = useState({});
  const { onClick, tl } = props;
  const size = useWindowSize();
  const { width } = size;

  useEffect(() => {
    setAnimation(
      gsap.fromTo(
        ".nextButton",
        { scale: 0.99, y: "-50%", x: "-100%" },
        {
          scale: 0.8,
          paused: true,
          duration: 0.2,
          ease: "linear",
        }
      )
    );
  }, []);
  const handleClick = () => {
    if (tl.totalProgress() === 0) {
      animation.play().then(() => {
        animation.reverse();
      });
      onClick();
    }
  };

  return (
    <button
      className="nextButton"
      onClick={handleClick}
      onMouseEnter={() =>
        setNextButtonImg(
          `${process.env.PUBLIC_URL}/images/gify/linia-owal-czerwona-anim.gif`
        )
      }
      onMouseLeave={() =>
        setNextButtonImg(
          `${process.env.PUBLIC_URL}/images/slider/zakreslenie-czerwone.png`
        )
      }
    >
      <img
        src={`${process.env.PUBLIC_URL}/images/slider/strzalka-prawo.png`}
        alt="Strzalka prawo"
        style={width > 1023 ? { minWidth: "89.1px" } : null}
      />

      <p
        style={{
          fontSize: "16px",
          fontWeight: "700",
          color: "#a71814",
          marginTop: "30px",
        }}
      >
        następna
      </p>

      <img src={nextButtonImg} alt="Slider linia prawo" className="ovalLineAnimation" />
    </button>
  );
}

const Fade = ({ handleMobileProductListClick }) => {
  const size = useWindowSize();
  const history = useHistory();
  const [index, setIndex] = useState(0);
  const [clickable, setClickable] = useState(true);
  const [imagesReady, setImagesReady] = useState(false);
  const { width } = size;

  function allLoaded() {
    setImagesReady(true);
  }

  let number = 0;

  useEffect(() => {
    pizzas.forEach((pizza) => {
      const img = new Image();
      img.src = pizza.pizzaBoxFallback;
      img.onload = () => {
        number++;

        if (number === pizzas.length) {
          allLoaded();
        }
      };
    });
  }, [number]);

  useEffect(() => {
    tl = gsap.timeline({ paused: true });
    if (width > 1023 && imagesReady) {
      tl.to(".sliderImg", { duration: 0.2, y: "5%", x: "5px" }).to(
        ".sliderImg",
        {
          duration: 1,
          y: "-100%",
          x: "-110px",
        }
      );
    }
  }, [width, imagesReady]);

  const onMouseEnterHandler = () => {
    if (width > 1023) {
      tl.play();
    }
  };
  const onMouseLeaveHandler = () => {
    if (width > 1023) {
      tl.reverse();
    }
  };

  const onSliderChange = (newIndex) => {
    if (tl.totalProgress() === 0) {
      setClickable(true);
      setIndex(newIndex);
    }
  };

  const onClick = () => {
    history.push({
      pathname: pizzas[index].path,
    });
    window.scrollTo(0, 0);
  };

  const settings = {
    customPaging: function (pizza) {
      return <PizzaMenu pizza={pizza} />;
    },
    dotsClass: "sliderDots",
    fade: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    dots: true,
    autoplaySpeed: 3000,
    cssEase: "linear",
    prevArrow: <SamplePrevArrow tl={tl} />,
    nextArrow: <SampleNextArrow tl={tl} />,
    afterChange: onSliderChange,
    beforeChange: () => setClickable(false),
  };
  return (
    <div className="sliderContainer">
      <link
        rel="stylesheet"
        type="text/css"
        charSet="UTF-8"
        href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick.min.css"
      />
      <link
        rel="stylesheet"
        type="text/css"
        href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick-theme.min.css"
      />
      <Slider {...settings}>
        {pizzas.map((pizza) => {
          return (
            <div key={pizza.id} style={{ position: "relative" }}>
              <div
                className="sliderImgContainer"
                onMouseOver={onMouseEnterHandler}
                onMouseLeave={onMouseLeaveHandler}
              >
                {width > 1023 ? (
                  <picture>
                    <source srcSet={pizza.pizzaBox} type="image/webp" />
                    <source srcSet={pizza.pizzaBoxFallback} type="image/png" />
                    <img
                      style={{ zIndex: 10, position: "relative" }}
                      className="sliderImg"
                      alt={pizza.alt}
                      onClick={null}
                    />
                  </picture>
                ) : (
                  <img
                    src={pizza.pizzaBoxMobile}
                    style={{ zIndex: 10, position: "relative" }}
                    className="sliderImg"
                    alt={pizza.alt}
                    onClick={() => clickable && onClick()}
                  />
                )}

                {width > 1023 && imagesReady ? (
                  <picture>
                    <source srcSet={pizza.pizza} type="image/webp" />
                    <source srcSet={pizza.pizzaFallback} type="image/png" />
                    <img
                      className="sliderPizza"
                      alt={pizza.alt}
                      onClick={() => clickable && onClick()}
                    />
                  </picture>
                ) : null}
              </div>
            </div>
          );
        })}
      </Slider>

      <div
        className="choosePizzaMenu"
        style={{
          backgroundImage: `url(${process.env.PUBLIC_URL}/images/mobile-pizza-menu.png)`,
        }}
      >
        <a href="#" onClick={handleMobileProductListClick}>
          WYBIERZ <br />
          SMAK PIZZY
        </a>
      </div>
    </div>
  );
};

export default Fade;
