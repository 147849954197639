/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import CloseIcon from "./icons/CloseIcon";
import Cookie from "universal-cookie";
import ReactGA from "react-ga";
import TagManager from 'react-gtm-module';
import CookieImg from "./images/cookie.webp";
import "./Cookies.scss";

var gaid = "UA-72039126-1";
var gtmid = "GTM-WLKJN3";
const tagManagerArgs = {
    gtmId: gtmid
};

const initGA = () => {
    ReactGA.initialize(gaid);
    ReactGA.pageview(window.location.pathname + window.location.search);
};

const initGTM = () => {
    TagManager.initialize(tagManagerArgs);
};

function gtag() {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push(arguments);
}

const handleCookie = (cookieText) => {
    window.dataLayer = []
    gtag('consent', 'default', {
        'ad_storage':  cookieText.includes("statistics:true") ? 'granted' : 'denied',
        'ad_user_data': cookieText.includes("userData:true") ? 'granted' : 'denied',
        'ad_personalization': cookieText.includes("personalization:true") ? 'granted' : 'denied',
        'analytics_storage': cookieText.includes("marketing:true") ? 'granted' : 'denied'
    });
    initGTM();
    console.log("cookieText", window.dataLayer);
}

class NewCookies extends React.Component {
    constructor(props) {
        super(props);
        window.cookiesComponent = this;
        this.state = {
            showCookies: false,
            showLong: false,
            required: true,
            statistics: false,
            marketing: false,
            preferences: false,
            personalization: false,
            userData: false,
            acceptAll: false,
            showDetails: false,
            cookies: null,
        };
        this.cookies = new Cookie('guseppe_wciaga_cookies');
    }

    componentDidMount() {
        if (this.cookies.get('guseppe_wciaga_cookies') === undefined) {
            gtag('consent', 'default', {
                ad_storage: 'denied',
                analytics_storage: 'denied',
                personalization_storage: 'denied',
                functionallity_storage: 'denied',
                security_storage: 'denied',
            });
        } else {
            handleCookie(this.cookies.get('guseppe_wciaga_cookies'));
        }
    }

    componentDidUpdate() {
        if (this.state.showCookies && !this.cookies.guseppe_wciaga_cookies) {
            document.body.classList.add("no-scroll");
        } else if (this.cookies.guseppe_wciaga_cookies) {
            document.body.classList.remove("no-scroll");
        } else {
            document.body.classList.remove("no-scroll");
        }
    }

    closeCookies() {
        this.setOpen(false);
        this.setState({ showLong: false });
        handleCookie(this.cookies.get('guseppe_wciaga_cookies'));
        document.getElementsByTagName('body')[0].style.overflow = 'visible';
    };

    openLongCookies() {
        this.setState({ showLong: true });
        this.setOpen(true);
        document.getElementsByTagName('body')[0].style.overflow = 'hidden';
    };

    openShortCookies() {
        this.setState({ showLong: false });
        this.setOpen(true);
    }

    setOpen = (bool) => {
        this.setState({ showCookies: bool });
    };

    setStatistics = (bool) => {
        this.state.statistics = bool;
        this.setState({ statistics: bool });
    };

    setMarketing = (bool) => {
        this.state.marketing = bool;
        this.setState({ marketing: bool });
    };

    setPreferences = (bool) => {
        this.state.preferences = bool;
        this.setState({ preferences: bool });
    };

    setPersonalization = (bool) => {
        this.state.personalization = bool;
        this.setState({ personalization: bool });
    };

    setUserData = (bool) => {
        this.state.userData = bool;
        this.setState({ userData: bool });
    };

    setRequired = (bool) => {
        this.state.required = bool;
        this.setState({ required: bool });
    };

    setAcceptAll = (bool) => {
        this.state.acceptAll = bool;
        this.setState({ acceptAll: bool });
    };

    handleAccept = () => {
        var date = new Date()
        this.cookies.set('guseppe_wciaga_cookies', `required:${this.state.required};statistics:${this.state.statistics};marketing:${this.state.marketing};userData:${this.state.userData};personalization:${this.state.personalization}`, { expires: new Date(date.setMonth(date.getMonth() + 1)),
            path: '/',
            sameSite: 'lax',
            secure: false});
        this.closeCookies();
    };

    handleAcceptAll = () => {
        this.setStatistics(true);
        this.setMarketing(true);
        this.setUserData(true);
        this.setPersonalization(true);
        this.setRequired(true);
        this.setAcceptAll(true);
        this.handleAccept();
    };

    handleDeclineAll = () => {
        this.setStatistics(false);
        this.setMarketing(false);
        this.setUserData(false);
        this.setPersonalization(false);
        this.setRequired(false);
        this.setAcceptAll(false);
        this.handleAccept();
    };

    handleExpandCookiesInfo = (btnId) => {
        const element = document.getElementById('cookies_expandable_content_' + btnId);
        const btn = document.getElementById('cookies_expandable_btn_' + btnId);
        if (element.style.display === "none") {
            element.style.display = "block";
            btn.innerHTML = "-";
        } else {
            element.style.display = "none";
            btn.innerHTML = "+";
        }
    }

    render() {
        return (
            <div>
                {this.cookies.get('guseppe_wciaga_cookies') === undefined || this.state.showCookies ? (this.state.showLong ? (<div className="cookies">
                    <div className="cookies__container" >
                        <button type="button" className="cookies__close-button" onClick={() => this.handleAcceptAll()} > <CloseIcon /> </button>
                        <div className="cookies__content">
                            <p className="cookies__content__header">Centrum preferencji prywatności</p>
                            <div className="cookies__content__text">
                                <p style={{ color: "#fffad4", margin: "0px" }}>
                                    Wykorzystujemy pliki cookie do spersonalizowania treści
                                    i&nbsp;reklam aby analizować ruch w naszej witrynie oraz w celach
                                    statystycznych. Informacje o tym, jak korzystasz z&nbsp;naszej
                                    witryny, udostępniamy partnerom reklamowym i&nbsp;analitycznym.
                                    Partnerzy mogą połączyć te informacje z&nbsp;innymi danymi
                                    otrzymanymi od Ciebie lub uzyskanymi podczas korzystania
                                    z&nbsp;ich usług. Kontynuując korzystanie z&nbsp;naszej witryny,
                                    zgadzasz się na używanie plików cookie. Równocześnie informujemy,
                                    że Administratorem Państwa danych jest Dr. Oetker Polska Sp.
                                    z&nbsp;o.o., Gdańsk (80-339) adres: Dickmana 14/15 więcej
                                    informacji o&nbsp;przetwarzaniu danych osobowych oraz mechanizmie
                                    plików cookie znajdą Państwo w&nbsp;polityce cookie.
                                    <br />
                                    <a target="_blank" rel="noreferrer" className="cookiesInlineLink" href={"https://www.oetker.pl/pl-pl/dodatkowe-informacje/polityka-prywatnosci"}>Polityka prywatności</a>
                                </p>
                                <button className="cookies_accept_all_btn cookies_btn_white cookies_btn  " onClick={() => this.handleAcceptAll()}>Zezwól na wszystkie</button>
                                <p className="cookies__content__header" style={{ marginTop: "35px" }}>Zarządzaj preferencjami zgody</p>
                                <hr className="cookies__content__hr" style={{ height: "1px" }} />
                                <div className="cookies_expandable">
                                    <div className="cookies_expandable_header">
                                        <div><button className="cookies_expandable_btn" id="cookies_expandable_btn_0" onClick={() => this.handleExpandCookiesInfo(0)}>+</button></div>
                                        <div className="cookies__content__header cookies_expandable_header_text">Wymagane</div>
                                        <div style={{ flexGrow: "100" }}></div>
                                        <div className="cookies__content__header cookies_expandable_header_text" style={{ float: "right" }}>Zawsze aktywne</div>
                                    </div>
                                    <div className="cookies_expandable_content" id="cookies_expandable_content_0">
                                        Są to pliki cookie niezbędne do zapewnienia działania podstawowych funkcji strony internetowej, takich jak nawigacja na stronie i funkcje związane z bezpieczeństwem. Strona internetowa nie może funkcjonować poprawnie bez tych ciasteczek.
                                        <br /><br />
                                        Nazwa ciasteczka: guseppe_wciaga_cookies
                                        <br />
                                        Okres: 1 miesiąc
                                        <br />
                                        Opis: Ciastko służy zapisaniu, czy użytkownik zaakceptował ciasta oraz jakiego typu ciastka zaakceptował, (w takim wypadku nie wyświetlamy zgody drugi raz)
                                        <br /><br />
                                    </div>
                                </div>
                                <hr className="cookies__content__hr" />
                                <div className="cookies_expandable">
                                    <div className="cookies_expandable_header">
                                        <div> <button className="cookies_expandable_btn" id="cookies_expandable_btn_1" onClick={() => this.handleExpandCookiesInfo(1)}>+</button></div>
                                        <div className="cookies__content__header cookies_expandable_header_text">Statystyka</div>
                                        <div style={{ flexGrow: "100" }}></div>
                                        <div className="cookies_expandable_checkbox">
                                            <input className="tgl tgl-light" id="cb1-6" type="checkbox" checked={this.state.statistics} onChange={(e) => this.setStatistics(!this.state.statistics)} />
                                            <label className="tgl-btn" htmlFor="cb1-6" />
                                        </div>
                                    </div>
                                    <div className="cookies_expandable_content" id="cookies_expandable_content_1">
                                        Są to pliki cookie zbierane w celach statystycznych i analitycznych. Anonimowe monitorowanie wizyt na stronie pozwala nam ocenić skuteczność konkretnych rozwiązań, abyśmy mogli na tej podstawie optymalizować treści i udoskonalać swoją obecność w internecie.
                                        <br /><br />
                                        Nazwa ciasteczka: _gat_UA-72039126-1
                                        <br />
                                        Okres: 1 minuta
                                        <br />
                                        Opis: Ciastko wspomagające mechanizmy ciastka _gat
                                        <br /><br />
                                        Nazwa ciasteczka: _gat
                                        <br />
                                        Okres: 1 minuta
                                        <br />
                                        Opis: Identyfikator użytkownika dla usługi śledzącej Google Universal Analytics zabezpieczający przed zbyt dużym przeciążeniem napływających danych
                                        <br /><br />
                                        Nazwa ciasteczka: _ga
                                        <br />
                                        Okres: 2 lata
                                        <br />
                                        Opis: Identyfikator użytkownika dla usługi śledzącej Google Universal Analytics
                                        <br /><br />
                                        Nazwa ciasteczka: _gid
                                        <br />
                                        Okres: 1 dzień
                                        <br />
                                        Opis: Identyfikator użytkownika dla usługi śledzącej Google Analytics
                                        <br /><br />
                                        Nazwa ciasteczka: _ga_8XCY1HE01E
                                        <br />
                                        Okres: 25 miesięcy
                                        <br />
                                        Opis: Identyfikator użytkownika dla usługi śledzącej Google Analytics 4
                                        <br /><br />
                                    </div>
                                </div>
                                <hr className="cookies__content__hr" />
                                <div className="cookies_expandable">
                                    <div className="cookies_expandable_header">
                                        <div><button className="cookies_expandable_btn" id="cookies_expandable_btn_2" onClick={() => this.handleExpandCookiesInfo(2)}>+</button></div>
                                        <div className="cookies__content__header cookies_expandable_header_text">Marketing</div>
                                        <div style={{ flexGrow: "100" }}></div>
                                        <div className="cookies_expandable_checkbox">
                                            <input className="tgl tgl-light" id="cb2-6" type="checkbox" checked={this.state.marketing} onChange={(e) => this.setMarketing(!this.state.marketing)} />
                                            <label className="tgl-btn" htmlFor="cb2-6" />
                                        </div>
                                    </div>
                                    <div className="cookies_expandable_content" id="cookies_expandable_content_2">
                                        W celu promowania niektórych usług, artykułów lub wydarzeń, możemy wykorzystywać reklamy, które wyświetlają się w innych serwisach internetowych. Ten rodzaj cookies służy do tego, aby wiadomości reklamowe były bardziej trafne oraz dostosowane do Twoich preferencji. Cookies zapobiegają też ponownemu pojawianiu się tych samych reklam. Reklamy te służą wyłącznie do informowania o prowadzonych działaniach. Więcej informacji możesz znaleźć w naszej polityce prywatności.                                        <br />
                                        <br /><br />
                                        Nazwa ciasteczka: _fbp
                                        <br />
                                        Okres: 3 miesiące
                                        <br />
                                        Opis: Ciastko używane przez Facebook do dostarczania serii produktów reklamowych, takich jak licytowanie w czasie rzeczywistym od reklamodawców zewnętrznych.
                                        <br /><br />
                                        Nazwa ciasteczka: tr
                                        <br />
                                        Okres: 1 sesja
                                        <br />
                                        Opis: Ciastko używane przez Facebook do dostarczania serii produktów reklamowych, takich jak licytowanie w czasie rzeczywistym od reklamodawców zewnętrznych.
                                        <br /><br />
                                        Nazwa ciasteczka: zync-uuid
                                        <br />
                                        Okres: 6 miesięcy
                                        <br />
                                        Opis: Uniwersalny identyfikator użytkownika
                                        <br /><br />
                                        Nazwa ciasteczka: sd-session-id
                                        <br />
                                        Okres: 6 miesięcy
                                        <br />
                                        Opis: Uniwersalny identyfikator użytkownika
                                        <br /><br />
                                        Nazwa ciasteczka: zync_tracking_optout
                                        <br />
                                        Okres: zgodnie z limitem przeglądarki, do 400 dni
                                        <br />
                                        Opis: Rezygnacja ze śledzenia
                                        <br /><br />

                                    </div>
                                </div>
                                <hr className="cookies__content__hr" />
                                <div className="cookies_expandable">
                                    <div className="cookies_expandable_header">
                                        <div><button className="cookies_expandable_btn" id="cookies_expandable_btn_3" onClick={() => this.handleExpandCookiesInfo(3)}>+</button></div>
                                        <div className="cookies__content__header cookies_expandable_header_text">Preferencje / Funkcjonalne</div>
                                        <div style={{ flexGrow: "100" }}></div>
                                        <div className="cookies_expandable_checkbox">
                                            <input className="tgl tgl-light" id="cb3-6" type="checkbox" checked={this.state.preferences} onChange={(e) => this.setPreferences(!this.state.preferences)} />
                                            <label className="tgl-btn" htmlFor="cb3-6" />
                                        </div>
                                    </div>
                                    <div className="cookies_expandable_content" id="cookies_expandable_content_3">
                                        Są to pliki cookie umożliwiające witrynie internetowej zapamiętywanie informacji, które zmieniają sposób działania lub wyglądu witryny, na przykład preferowany język lub region, w którym się znajdujesz.
                                        <br /><br />
                                        Nazwa ciasteczka: evo5_DROETKER
                                        <br />
                                        Okres: 6 miesięcy
                                        <br />
                                        Opis: Zainteresowania i wyniki każdego odwiedzającego
                                        <br /><br />
                                        Nazwa ciasteczka: evo5_DROETKER_popin
                                        <br />
                                        Okres: 6 miesięcy
                                        <br />
                                        Opis: Ocena statusu odwiedzającego do interakcji na stronie
                                        <br /><br />
                                        Nazwa ciasteczka: evo5__popin
                                        <br />
                                        Okres: 6 miesięcy
                                        <br />
                                        Opis: Ocena statusu odwiedzającego do interakcji na stronie
                                        <br /><br />
                                        Nazwa ciasteczka: evo5_popin_instance
                                        <br />
                                        Okres: 6 miesięcy
                                        <br />
                                        Opis: Ocena statusu odwiedzającego do interakcji na stronie dla osób nieakceptujących plików cookie stron trzecich.
                                        <br /><br />
                                        Nazwa ciasteczka: nmfirstparty
                                        <br />
                                        Okres: 6 miesięcy
                                        <br />
                                        Opis: Unikalny identyfikator dla każdego odwiedzającego, który nie akceptuje plików cookie stron trzecich
                                        <br /><br />
                                        Nazwa ciasteczka: nmfp_&lt;cookie&gt;
                                        <br />
                                        Okres: 6 miesięcy
                                        <br />
                                        Opis: Pliki cookie "evo5" powyżej jako pliki cookie strony pierwszej
                                        <br /><br />
                                        Nazwa ciasteczka: EVO5_OPT
                                        <br />
                                        Okres: Zgodnie z limitem przeglądarki, do 400 dni
                                        <br />
                                        Opis: Rezygnacja ze śledzenia
                                        <br /><br />
                                    </div>
                                </div>
                                <hr className="cookies__content__hr" />
                                <div className="cookies_expandable">
                                    <div className="cookies_expandable_header">
                                        <div><button className="cookies_expandable_btn" id="cookies_expandable_btn_4" onClick={() => this.handleExpandCookiesInfo(4)}>+</button></div>
                                        <div className="cookies__content__header cookies_expandable_header_text">Dane użytkownika</div>
                                        <div style={{ flexGrow: "100" }}></div>
                                        <div className="cookies_expandable_checkbox">
                                            <input className="tgl tgl-light" id="cb4-6" type="checkbox" checked={this.state.userData} onChange={(e) => this.setUserData(!this.state.userData)} />
                                            <label className="tgl-btn" htmlFor="cb4-6" />
                                        </div>
                                    </div>
                                    <div className="cookies_expandable_content" id="cookies_expandable_content_4">
                                        OPIS
                                    </div>
                                </div>
                                <hr className="cookies__content__hr" />
                                <div className="cookies_expandable">
                                    <div className="cookies_expandable_header">
                                        <div><button className="cookies_expandable_btn" id="cookies_expandable_btn_5" onClick={() => this.handleExpandCookiesInfo(5)}>+</button></div>
                                        <div className="cookies__content__header cookies_expandable_header_text">Personalizacja</div>
                                        <div style={{ flexGrow: "100" }}></div>
                                        <div className="cookies_expandable_checkbox">
                                            <input className="tgl tgl-light" id="cb5-6" type="checkbox" checked={this.state.personalization} onChange={(e) => this.setPersonalization(!this.state.personalization)} />
                                            <label className="tgl-btn" htmlFor="cb5-6" />
                                        </div>
                                    </div>
                                    <div className="cookies_expandable_content" id="cookies_expandable_content_5">
                                        OPIS
                                    </div>
                                </div>
                                <hr className="cookies__content__hr" style={{ height: "1px" }} />
                            </div>
                        </div>

                        <div className="cokkies_button_container">
                            <button className="cookies_btn cookies_btn_red_light" style={{ width: "100%", marginTop: "25px" }} onClick={() => this.handleDeclineAll()}>Odrzucenie wszystkich</button>
                            <div style={{ flexGrow: "100" }}></div>
                            <button className="cookies_btn cookies_btn_red_light" style={{ width: "100%", marginTop: "25px" }} onClick={() => this.handleAccept()}>Potwierdzenie moich wyborów</button>
                        </div>
                    </div>
                </div>) : (
                    <div className="cookies_short">
                        <div className="cookies_short_column" id="cookie_short_column_1" >
                            <img src={CookieImg} alt="cookie" />
                        </div>

                        <div className="cookies_short_column" id="cookie_short_column_2" >
                            <p className="cookies_short_text">
                                Poprzez kliknięcie na "Akceptuj wszystkie pliki cookie" jest wyrażona zgoda na wykorzystanie plików cookie na swoim urządzeniu w celu usprawnienia korzystania z nawigacji strony, analizowania wykorzystania strony i wspracia naszych działań marketingowych.  <a rel="noreferrer" target="_blank" className="cookiesInlineLink" href={"https://www.oetker.pl/pl-pl/dodatkowe-informacje/polityka-prywatnosci"}>Polityka prywatności</a>
                            </p>
                        </div>

                        <div className="cookies_short_column" id="cookie_short_column_3" >
                            <div className="cookies_short_btn_column">
                                <button className="cookies_btn cookies_btn_white" onClick={() => this.handleAcceptAll()}>Akceptuj wszystkie pliki cookie</button>
                                <button className="cookies_btn cookies_btn_red" onClick={() => this.openLongCookies()}>Ustawienia plików cookie</button>
                            </div>
                        </div>

                        <div className="cookies_short_column" id="cookie_short_column_4" >
                            <div className="cookies_short_btn_column">
                                <button className="cookies_btn cookies_btn_white" onClick={() => this.handleAcceptAll()}>Zezwól na wszystkie</button>
                                <button className="cookies_btn cookies_btn_red" onClick={() => this.handleAccept()}>Potwierdzenie moich wyborów</button>
                            </div>
                        </div>

                    </div>)) :
                    <div className="cookies_floating">
                        <img src={CookieImg} alt="cookie" onClick={() => this.openLongCookies()} />
                    </div>}
            </div>
        );
    }
}

export default NewCookies;