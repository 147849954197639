import { withRouter } from "react-router-dom";
import ReactGA from "react-ga";
const RouteChangeTracker = (props) => {
  const { history, children } = props;
  history.listen((location, action) => {
    ReactGA.set({ page: location.pathname });
    ReactGA.pageview(location.pathname);
  });
  return children;
};

export default withRouter(RouteChangeTracker);
