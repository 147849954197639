/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import NavBar from "../../components/NavBar/NavBar";
import Main from "./components/Main/Main";
import Instruction from "./components/Instruction/Instruction";
import Footer from "../../components/Footer/Footer";
import Informations from "./components/Informations/Informations";
import useWindowSize from "../../Hooks/UseWindowSize";
import "./SalamiSubpage.scss";

function SalamiSubpage() {
  const size = useWindowSize();
  const { width } = size;
  const [isMobileProductListOpen, setIsMobileProductListOpen] = React.useState(
    false
  );

  const handleCloseNavbar = () => {
    setIsMobileProductListOpen(false);
  };

  const handleMobileProductListClick = (e) => {
    e.preventDefault();
    setIsMobileProductListOpen(true);
  };

  return (
    <div className="subPage">
      <NavBar
        isMobileProductListOpen={isMobileProductListOpen}
        onClose={handleCloseNavbar}
      />
      <Main />
      <Instruction />
      {width > 1023 ? null : <Informations />}
      <div
        className="choosePizzaMenu"
        style={{
          backgroundImage: `url(${process.env.PUBLIC_URL}/images/mobile-pizza-menu.png)`,
        }}
      >
        <a href="#" onClick={handleMobileProductListClick}>
          WYBIERZ <br />
          SMAK PIZZY
        </a>
      </div>
      <Footer />
    </div>
  );
}

export default SalamiSubpage;
