import React from "react";
import "./Instruction.scss";
import OvenIcon from "./images/ovenIcon.png";
import PizzaSalamiIcon from "./images/pizzaSalamiIcon.png";
import ClockSalami from "./images/clockSalami.png";
import Number1 from "./images/1.png";
import Number2 from "./images/2.png";
import Number3 from "./images/3.png";
const Instruction = () => {
  // eslint-disable-next-line

  return (
    <section className="instruction">
      <h2 className="instructionTitle">Instrukcja pieczenia</h2>
      <div className="line"></div>
      <div className="steps">
        <div className="step1">
          <div className="instructionStep1Container">
            <img src={Number1} alt="1" />
          </div>
          <p className="instruction">
            Piekarnik nagrzać <br />
            do <strong>220°C.</strong>
          </p>
          <div className="temperatureIcon">
            <img
              src={PizzaSalamiIcon}
              alt="Temperatura"
              className="temperatureIcon"
            />
          </div>
        </div>

        <div className="step2">
          <div className="instructionStep2Container">
            <img src={Number2} alt="2" />
          </div>
          <p className="instruction c2">
            Usunąć folię. Pizzę piec na kratce na środkowym poziomie
            w&nbsp;programie grzanie góra-dół.
          </p>

          <div className="iconOven">
            <img src={OvenIcon} alt="Piekarnik" className="iconOven" />
          </div>
        </div>

        <div className="step3">
          <div className="instructionStep3Container">
            <img src={Number3} alt="3" />
          </div>
          <p className="instruction">
            <strong>Czas pieczenia: 13-16 min </strong>
            do czasu rozpuszczenia sera i&nbsp;zarumieniania brzegów.
          </p>

          <div className="iconClock">
            <img src={ClockSalami} alt="Zegar" className="salamiClock" />
          </div>
        </div>
      </div>
    </section>
  );
};

export default Instruction;
