import React, { useState, useEffect } from "react";
import "./IngredientsDesktop.scss";
import * as ScrollMagic from "scrollmagic";
import gsap from "gsap";
import SalamiIngredients2 from "./images/salami-papryka-kukurydza.png";
import SalamiIngredients3 from "./images/kukurydza.png";

const IngredientsDesktop = () => {
  // eslint-disable-next-line
  const [scrollMagic, setScrollMagic] = useState({
    controller: new ScrollMagic.Controller(),
    timelineOne: gsap.timeline(),
  });
  const { controller, timelineOne } = scrollMagic;
  useEffect(() => {
    // ingredients animation
    timelineOne.staggerFromTo(
      [".salamiIngredients2", ".salamiIngredients3"],
      0.5,
      { y: "50px", opacity: 0 },
      { y: "0", opacity: 1 },
      0.25
    );

    new ScrollMagic.Scene({
      triggerElement: ".ingredients_desktop2",
      triggerHook: 0.8,
    })
      .setTween(timelineOne)
      .addTo(controller);
    // eslint-disable-next-line
  }, []);
  return (
    <div className="ingredients_desktop2">
      <img
        src={SalamiIngredients2}
        alt="Salami papryka kukurydza"
        className="salamiIngredients2"
      />
      <img
        src={SalamiIngredients3}
        alt="Kukurydza"
        className="salamiIngredients3"
      />
    </div>
  );
};

export default IngredientsDesktop;
