import React from "react";
import AboutMobile from "./components/AboutMobile/AboutMobile";
import AboutDesktop from "./components/AboutDesktop/AboutDekstop";
import useWindowSize from "../../Hooks/UseWindowSize";
import "./About.scss";

const About = () => {
  const size = useWindowSize();
  const { width } = size;

  return (
    <div className="aboutSection">
      {width >= 1024 ? <AboutDesktop /> : <AboutMobile />}
    </div>
  );
};

export default About;
