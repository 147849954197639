import React from "react";
import OvenIcon from "../images/ovenIcon.png";
import PizzaSalamiIcon from "../images/pizzaSalamiIcon.png";
import ClockSalami from "../images/clockSalami.png";
import Number1 from "../images/1.png";
import Number2 from "../images/2.png";
import Number3 from "../images/3.png";
import "./InstructionDesktop.scss";
const InstructionDesktop = () => {
  return (
    <section className="instruction_desktop">
      <h2 className="instructionTitle_desktop">Instrukcja pieczenia</h2>
      <div className="line_desktop"></div>
      <div className="steps_desktop">
        <div className="step_desktop1">
          <div className="instructionStep1Container_desktop">
            <img src={Number1} alt="1" />
          </div>
          <p className="instruction_desktop">
            Piekarnik nagrzać <br />
            do <strong>220°C.</strong>
          </p>
          <div className="temperatureIcon_desktop">
            <img
              src={PizzaSalamiIcon}
              alt="Temperatura"
              className="temperatureIcon_desktop"
            />
          </div>
        </div>

        <div className="step_desktop2">
          <div className="instructionStep2Container_desktop">
            <img src={Number2} alt="2" />
          </div>
          <p className="instruction_desktop">
            Usunąć folię. <br />
            Pizzę piec na kratce <br />
            na środkowym poziomie <br />
            w&nbsp;programie grzanie góra-dół.
          </p>
          <div className="iconOven_desktop">
            <img src={OvenIcon} alt="Piekarnik" className="iconOven_desktop" />
          </div>
        </div>

        <div className="step_desktop3">
          <div className="instructionStep3Container_desktop">
            <img src={Number3} alt="3" />
          </div>
          <p className="instruction_desktop">
            <strong>
              Czas pieczenia: 13-16 min <br />
            </strong>
            do czasu rozpuszczenia <br />
            sera i&nbsp;zarumieniania <br />
            brzegów.
          </p>

          <div className="iconClock_desktop">
            <img
              src={ClockSalami}
              alt="Zegar"
              className="salamiClock_desktop"
            />
          </div>
        </div>
      </div>
    </section>
  );
};

export default InstructionDesktop;
