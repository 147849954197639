import React, { useState, useEffect } from "react";
import * as ScrollMagic from "scrollmagic";
import Underline from "../../../pages/SalamiSubpage/components/Informations/images/czerwone-podkreslenie.png";
import gsap from "gsap";
import LogoDrOetker from "../../../images/Footer/logo-oetker.png";
import Basil from "../../../images/Footer/bazylia.png";
import Tomato from "../../../images/Footer/pomidor.png";
import PizzaSlice from "../../../images/Footer/salami-kawalek.webp";
import PizzaSliceFallback from "../../../images/Footer/png/salami-kawalek.png";
import "./FooterDesktop.scss";

const FooterDesktop = () => {
  // eslint-disable-next-line
  const [scrollMagic, setScrollMagic] = useState({
    controller: new ScrollMagic.Controller(),
    timelineOne: gsap.timeline(),
  });
  const { controller, timelineOne } = scrollMagic;

  useEffect(() => {
    // footer animation
    timelineOne.staggerFromTo(
      ".contactContainerDesktop",
      0.5,
      { y: "0", opacity: 0 },
      { y: "0", opacity: 1 }
    );
    new ScrollMagic.Scene({
      triggerElement: ".footerDesktopAnimation",
      triggerHook: 0.9,
      duration: 0,
    })
      .setTween(timelineOne)
      .addTo(controller);
    // eslint-disable-next-line
  }, []);

  const openCookies = (e) => {
    e.preventDefault();
    window.cookiesComponent.openLongCookies();
  };

  return (
    <div className="footerDesktopAnimation">
      <div className="contactContainerDesktop" id="footer">
        <div className="contactInfoContainer">
          <img
            src={LogoDrOetker}
            alt="DrOetker"
            style={{ display: "block", width: "80px", marginBottom: "30px" }}
          />

          <p className="consumerServiceDesktop">Serwis Konsumenta</p>

          <img
            src={Underline}
            alt="Linia"
            className="consumerServiceDesktopUnderline"
          />

          <p className="contactIntroDesktop">
            Czekamy na Twoje pytania, prośby i&nbsp;sugestie. <br />
            Poniżej znajdziesz adres.
          </p>
          <p className="addressDesktop">
            Dr. Oetker Polska Sp. z o.o. <br />
            Serwis Konsumenta <br />
            ul. Adm. Dickmana 14/15 <br />
            80-339 Gdańsk
          </p>
          <p className="phoneDesktop">Infolinia: 585 111 001*</p>

          <img src={Underline} alt="Linia" className="phoneDesktopUnderline" />

          <p className="emailDesktop">
            email: <strong>serwis@oetker.pl</strong>
          </p>
          <p className="infoDesktop">
            *Infolinia czynna jest od poniedziałku do soboty w&nbsp;godz. 10-18
            <br />
            oraz w&nbsp;niedzielę w&nbsp;godz. 10-13. <br />
            Koszt połączenia zgodnie z&nbsp;cennikiem Operatora.
          </p>
          <p className="copyrightDesktop">
            @ Copyright 2023 Dr. Oetker Polska Sp. z o.o. Wszelkie prawa
            zastrzeżone. <br />
            <a target="_blank" rel="noreferrer" className="inlineLink" href={"https://www.oetker.pl/pl-pl/dodatkowe-informacje/polityka-prywatnosci"}>Polityka prywatności</a> | <a className="inlineLink" href='/' onClick={openCookies}>Ustawienia plików cookies</a>.
          </p>
        </div>
        <div className="imagesContainerDesktop">
          <img className="contactBasilDesktop" src={Basil} alt="Bazylia" />

          <img className="contactTomatoDesktop" src={Tomato} alt="Pomidor" />
          <picture>
            <source srcSet={PizzaSlice} type="image/webp" />
            <source srcSet={PizzaSliceFallback} type="image/png" />
            <img
              className="contactPizzaSliceDesktop"
              alt="Salami kawalek pizzy"
            />
          </picture>
        </div>
      </div>
    </div>
  );
};

export default FooterDesktop;
