import React, { useState, useEffect } from "react";
import * as ScrollMagic from "scrollmagic";
import gsap from "gsap";
import "./AboutMobile.scss";

const AboutMobile = () => {
  // eslint-disable-next-line
  const [scrollMagic, setScrollMagic] = useState({
    controller: new ScrollMagic.Controller(),
    timelineOne: gsap.timeline(),
  });

  const { controller, timelineOne } = scrollMagic;

  useEffect(() => {
    timelineOne.staggerFromTo(
      [
        "#aboutAnimationMobile",
        ".aboutDescription",
        ".basil",
        ".tomato",
        ".mushroom",
        ".mushroom2",
      ],
      0.5,
      { y: "120px", opacity: 0 },
      { y: "0", opacity: 1 },
      0.25
    );
    new ScrollMagic.Scene({
      triggerElement: "#aboutTrigger",
      triggerHook: 0.9,
    })
      .setTween(timelineOne)
      .addTo(controller);
    // eslint-disable-next-line
  }, []);
  return (
    <>
      <div style={{ flexBasis: "50%" }} id="aboutTrigger">
        <div id="aboutAnimationMobile">
          <h2 className="aboutTitle">
            Guseppe - pizza,
            <br /> która wciąga!
          </h2>

          <img
            src={`${process.env.PUBLIC_URL}/images/About/podkreslenie.png`}
            alt="Linia"
            className="underline"
          />
        </div>
        <div className="aboutDescription">
          <p>
            Pyszne ciasto, delikatnie puszyste w środku i&nbsp;jednocześnie
            przyjemnie chrupiące na zewnątrz, to właśnie tajemnica
            niepowtarzalnego smaku pizzy Guseppe. Dodatki specjalnie dobrane
            i&nbsp;lubiane - ułożone aż po brzegi. Całości smaku dopełniają
            aromatyczne przyprawy i&nbsp;sosy.
          </p>
          <p className="summary">
            Guseppe to pyszna pizza mrożona, zabawa smakiem dla każdego!
          </p>
        </div>
      </div>
      <div className="imagesContainer">
        <img
          src={`${process.env.PUBLIC_URL}/images/About/bazylia.png`}
          alt="Bazylia"
          className="basil"
        />

        <img
          src={`${process.env.PUBLIC_URL}/images/About/pomidor.png`}
          alt="Pomidor"
          className="tomato"
        />

        <img
          src={`${process.env.PUBLIC_URL}/images/About/pieczarka.webp`}
          alt="Pieczarka"
          className="mushroom"
        />

        <img
          src={`${process.env.PUBLIC_URL}/images/About/pieczarka-polowa.webp`}
          alt="Pieczarka"
          className="mushroom2"
        />
        <picture>
          <source
            srcSet={`${process.env.PUBLIC_URL}/images/About/kawalek-pizzy.webp`}
            type="image/webp"
          />
          <source
            srcSet={`${process.env.PUBLIC_URL}/images/About/png/kawalek-pizzy.png`}
            type="image/png"
          />
          <img alt="Pizza kawalek" className="pizzaSlice" />
        </picture>
      </div>
    </>
  );
};

export default AboutMobile;
