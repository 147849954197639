import React from "react";
import useWindowSize from "../../Hooks/UseWindowSize";
import FooterDesktop from "./components/FooterDesktop";
import FooterMobile from "./components/FooterMobile/FooterMobile";
// import "./Footer.scss";

const Footer = () => {
  const size = useWindowSize();
  const { width } = size;
  if (width) {
    return <>{width >= 1024 ? <FooterDesktop /> : <FooterMobile />}</>;
  }
  return null;
};

export default Footer;
