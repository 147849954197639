import React from "react";
import { useHistory } from "react-router-dom";
import { useInView } from "react-intersection-observer";
import * as ScrollMagic from "scrollmagic";
import useWindowSize from "../../Hooks/UseWindowSize";
import gsap from "gsap";
import "./GameSection.scss";

const GameSection = () => {
  // eslint-disable-next-line
  const [scrollMagic, setScrollMagic] = React.useState({
    controller: new ScrollMagic.Controller(),
    timelineOne: gsap.timeline(),
  });
  const { controller, timelineOne } = scrollMagic;
  const iframeRef = React.createRef();
  const size = useWindowSize();
  const { width } = size;
  const history = useHistory();
  const { ref, inView } = useInView({ threshold: 0.8 });

  React.useEffect(() => {
    if (inView) {
      history.replace("#gra");
    } else {
      history.replace("");
    }

    // eslint-disable-next-line
  }, [inView]);

  React.useEffect(() => {
    timelineOne.fromTo(
      ".gameContainer",
      { y: "100px", opacity: 0 },
      { y: "0", opacity: 1 }
    );

    new ScrollMagic.Scene({
      triggerElement: "#gra",
      triggerHook: 0.9,
    })
      .setTween(timelineOne)
      .addTo(controller);
    // eslint-disable-next-line
  }, []);
  return (
    <section ref={ref} id="gra" style={{ paddingTop: "60px", margin: "0"}}>
      <div className="gameContainer">
        {width > 1023 ? (
          <iframe
            ref={iframeRef}
            src={`${process.env.PUBLIC_URL}/game/index.html`}
            frameBorder="0"
            title="game"
            allowFullScreen
            className="game"
          ></iframe>
        ) : (
          <div
            className="mobileGame"
            style={{
              backgroundImage: `url(${`${process.env.PUBLIC_URL}/images/MobileGame/game-background.webp`})`,
              height: "calc(90vh - 4.5em)",
              position: "relative",
              backgroundPosition: "center",
              backgroundSize: "cover",
            }}
          >
            <img
              src={`${process.env.PUBLIC_URL}/images/MobileGame/play.png`}
              alt="Zagraj"
              onClick={() => {
                history.push({
                  pathname: "/gra",
                });
                window.scroll({
                  top: 0,
                  left: 0,
                });
              }}
              style={{
                position: "absolute",
                top: "37%",
                width: "85%",
                left: "50%",
                transform: "translateX(-50%)",
              }}
            />
          </div>
        )}
      </div>
    </section>
  );
};

export default GameSection;
