import React, { useState, useEffect } from "react";
import "./Informations.scss";
import * as ScrollMagic from "scrollmagic";
import gsap from "gsap";
import useWindowSize from "../../../../Hooks/UseWindowSize";
import Ingredients2 from "./Ingredients2/Ingredients2";
import Arrow from "./images/arrow.png";
const Informations = () => {
  const [alergensShow, setAlergensShow] = useState(false);
  const [nutritionalValues, setNutritionalValues] = useState(false);
  const size = useWindowSize();
  const { width } = size;
  // eslint-disable-next-line
  const [scrollMagic, setScrollMagic] = useState({
    controller: new ScrollMagic.Controller(),
    timelineOne: gsap.timeline(),
  });
  const { controller, timelineOne } = scrollMagic;

  useEffect(() => {
    // instruction animation
    timelineOne.staggerFromTo(
      [".nutritionalContainer", ".alergensContainer"],
      0.5,
      { y: "100px", opacity: 0 },
      { y: "0", opacity: 1 },
      0.25
    );

    new ScrollMagic.Scene({
      triggerElement: width > 1023 ? ".skos2" : ".skos3",
      triggerHook: 0.95,
    })
      .setTween(timelineOne)
      .addTo(controller);
    // eslint-disable-next-line
  }, []);

  return (
    <div className="skos3">
      <Ingredients2 />
      <div className="informations">
        <div
          className="nutritionalContainer"
          onClick={() => {
            setAlergensShow(false);
            setNutritionalValues(!nutritionalValues);
          }}
        >
          <p className="title">Wartości odżywcze</p>
          <div className="lineInformations"></div>
          <div
            className="nutritionalValues"
            style={
              nutritionalValues
                ? {
                    display: "block",
                  }
                : {
                    display: "none",
                  }
            }
          >
            <div className="nutritionalValue">
              <p style={{ flexBasis: "50%", marginRight: "10px" }}></p>
              <p>100g</p>
              <p>380g</p>
              <p>GDA(%)</p>
            </div>
            <div className="nutritionalValue">
              <p style={{ flexBasis: "50%", marginRight: "10px" }}>
                Wartość energetyczna (kj)
              </p>
              <p>1046</p>
              <p>3975</p>
              <p>47</p>
            </div>
            <div className="nutritionalValue">
              <p style={{ flexBasis: "50%", marginRight: "10px" }}>
                Wartość energetyczna (kcal)
              </p>
              <p>249</p>
              <p>947</p>
              <p>47</p>
            </div>{" "}
            <div className="nutritionalValue">
              <p style={{ flexBasis: "50%", marginRight: "10px" }}>
                Tłuszcz (g) <br /> w tym kwasy
              </p>
              <p>10</p>
              <p>38</p>
              <p>54</p>
            </div>{" "}
            <div className="nutritionalValue">
              <p style={{ flexBasis: "50%", marginRight: "10px" }}>
                tłuszcze nasycone (g)
              </p>
              <p>3,7</p>
              <p>14</p>
              <p>70</p>
            </div>{" "}
            <div className="nutritionalValue">
              <p style={{ flexBasis: "50%", marginRight: "10px" }}>
                Węglowodany (g)
              </p>
              <p>31</p>
              <p>116</p>
              <p>45</p>
            </div>{" "}
            <div className="nutritionalValue">
              <p style={{ flexBasis: "50%", marginRight: "10px" }}>
                {" "}
                w tym cukry (g)
              </p>
              <p>3,6</p>
              <p>14</p>
              <p>16</p>
            </div>{" "}
            <div className="nutritionalValue">
              <p style={{ flexBasis: "50%", marginRight: "10px" }}>
                Błonnik (g)
              </p>
              <p>1,7</p>
              <p>6,3</p>
              <p/>
            </div>{" "}
            <div className="nutritionalValue">
              <p style={{ flexBasis: "50%", marginRight: "10px" }}>
                Białko (g)
              </p>
              <p>8,4</p>
              <p>32</p>
              <p>64</p>
            </div>{" "}
            <div className="nutritionalValue">
              <p style={{ flexBasis: "50%", marginRight: "10px" }}> Sól (g)</p>
              <p>1,2</p>
              <p>4,5</p>
              <p>75</p>
            </div>{" "}
          </div>
          <img
            src={Arrow}
            className="arrow"
            alt="Strzalka"
            style={
              nutritionalValues
                ? {
                    transform: "rotate(0)",
                  }
                : null
            }
          />
        </div>
        <div
          className="alergensContainer"
          onClick={() => {
            setNutritionalValues(false);
            setAlergensShow(!alergensShow);
          }}
        >
          <p className="title">Alergeny</p>
          <div className="lineAlergens"></div>
          <p
            className="alergensDescription"
            style={
              alergensShow
                ? {
                    display: "block",
                  }
                : {
                    display: "none",
                  }
            }
          >
            W tym miejscu zwracamy uwagę osobom cierpiącym na alergię lub inne
            nietolerancje, że niniejszy produkt może zawierać alergenne
            składniki, bądź śladowe ich ilości, które zgodnie z&nbsp;przepisami
            prawa wymagają znakowania, bowiem mogą wywołać reakcje alergiczne
            lub reakcje nietolerancji, a&nbsp;mianowicie:{" "}
            <strong>gluten, mleko, soja</strong>.
          </p>
          <img
            src={Arrow}
            alt="Strzalka"
            className="arrow"
            style={
              alergensShow
                ? {
                    transform: "rotate(0)",
                  }
                : null
            }
          />
        </div>
      </div>
    </div>
  );
};

export default Informations;
