import React from "react";
import Salami from "./images/Salami.png";
import Corn1 from "./images/kukurydza1.png";
import Corn2 from "./images/kukurydza2.png";
import Corn3 from "./images/kukurydza3.png";
import Corn4 from "./images/kukurydza4.png";
import Chilis from "./images/chilis.png";
import "./Ingredients2.scss";
const Ingredients2 = () => {
  return (
    <div className="ingredients2">
      <img src={Salami} alt="Salami" className="salami" />
      <img src={Corn1} alt="Kukurydza" className="corn1" />
      <img src={Corn2} alt="Kukurydza" className="corn2" />
      <img src={Corn3} alt="Kukurydza" className="corn3" />
      <img src={Corn4} alt="Kukurydza" className="corn4" />
      <img src={Chilis} alt="Chilis" className="chilis" />
    </div>
  );
};

export default Ingredients2;
