import React, { useState, useEffect } from "react";
import * as ScrollMagic from "scrollmagic";
import gsap from "gsap";
import { Link } from "react-router-dom";
import CircleAnimation from "../../pages/common/images/Circle.gif";
import "./More.scss";

const More = () => {
  const [findShopCircle, setFindShopCircle] = useState(
    `${process.env.PUBLIC_URL}/images/More/oval-line.png`
  );
  const [howToBakeCircle, setHowToBakeCircle] = useState(
    `${process.env.PUBLIC_URL}/images/More/oval-line.png`
  );
  // eslint-disable-next-line
  const [scrollMagic, setScrollMagic] = useState({
    controller: new ScrollMagic.Controller(),
    timelineOne: gsap.timeline(),
  });

  const onMouseOver = (place) => {
    if (place === "findShop") {
      setFindShopCircle(CircleAnimation);
    } else {
      setHowToBakeCircle(CircleAnimation);
    }
  };

  const onMouseLeave = (place) => {
    if (place === "findShop") {
      setFindShopCircle(`${process.env.PUBLIC_URL}/images/More/oval-line.png`);
    } else {
      setHowToBakeCircle(`${process.env.PUBLIC_URL}/images/More/oval-line.png`);
    }
  };

  const { controller, timelineOne } = scrollMagic;

  useEffect(() => {
    // findShopContainer animation
    timelineOne.to(".findShopContainer", { y: "0", opacity: 1, duration: 0.3 });
    new ScrollMagic.Scene({
      triggerElement: "#moreSection",
      triggerHook: 0.85,
      duration: 0,
    })
      .setTween(timelineOne)
      .addTo(controller);

    // findShopContainer animation
    timelineOne.to(".howToBakeContainer", {
      y: "0",
      opacity: 1,
      duration: 0.3,
    });
    new ScrollMagic.Scene({
      triggerElement: "#moreSection",
      triggerHook: 0.85,
      duration: 0,
    }).addTo(controller);

    // moreSectionVegetables animation
    timelineOne.to(".moreSectionVegetables", { y: "0", opacity: 1 });
    new ScrollMagic.Scene({
      triggerElement: "#moreSection",
      triggerHook: 0.85,
      duration: 0,
    }).addTo(controller);
    // eslint-disable-next-line
  }, []);

  return (
    <>
      <div className="moreSectionContainer" id="moreSection">
        <div
          style={{ transform: "translateY(120px)", opacity: 0 }}
          className="findShopContainer"
          onMouseLeave={() => onMouseLeave("findShop")}
          onMouseOver={() => onMouseOver("findShop")}
        >
          <Link to="/znajdz-sklep" style={{ margin: 0 }}>
            <img
              alt="Znajdz sklep"
              className="findShop"
              src={`${process.env.PUBLIC_URL}/images/More/znajdz-sklep.png`}
            />

            <img
              className="searchIcon"
              alt="Szukaj"
              src={`${process.env.PUBLIC_URL}/images/More/szukaj.png`}
            />

            <img src={findShopCircle} className="findShopCircle" alt="Kolko" />
          </Link>
        </div>
        <div
          style={{ transform: "translateY(120px)", opacity: 0 }}
          className="howToBakeContainer"
          onMouseLeave={() => onMouseLeave("howToBake")}
          onMouseOver={() => onMouseOver("howToBake")}
        >
          <Link to="/jak-upiec" style={{ margin: 0 }}>
            <img
              alt="Jak upiec"
              className="howToBake"
              src={`${process.env.PUBLIC_URL}/images/More/jak-upiec.png`}
            />

            <img
              className="checkIcon"
              alt="Sprawdz"
              src={`${process.env.PUBLIC_URL}/images/More/sprawdz.png`}
            />

            <img
              src={howToBakeCircle}
              className="howToBakeCircle"
              alt="Kolko"
            />
          </Link>
        </div>
      </div>

      <img
        src={`${process.env.PUBLIC_URL}/images/More/pieczarka-bazylia-pieprz.png`}
        alt="Pieczarka bazylia pieprz"
        className="moreSectionVegetables"
        style={{ transform: "translateY(120%)", opacity: 0 }}
      />
    </>
  );
};

export default More;
