import { useState } from "react";
import { css } from "@emotion/core";
import ClipLoader from "react-spinners/ClipLoader";

const override = css`
  display: block;
  margin: 0 auto;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(50%, 50%);
`;

function Loader() {
  // eslint-disable-next-line
  let [loading, setLoading] = useState(true);

  return (
    <div className="sweet-loading">
      <ClipLoader color="#ff0000" loading={loading} css={override} size={150} />
    </div>
  );
}

export default Loader;
